import { createApp } from "vue";
import { createPinia } from "pinia";
import { FragmentScriptHelper, loadScript } from "@/shared/helpers/fragmentScriptHelper";
import { Identity } from "@/shared/services/identity.service";

import { EnvironmentsApi } from "./api";

import App from "./App.vue";
import RegisterApp from "./RegisterApp.vue";
import router from "./router";
import AppInstaller from "./AppInstaller";
import UsersApi from "@/api/users";

import "@fortawesome/fontawesome-pro/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import "../scss/main.scss";

const isDevelopmentEnv = process.env.NODE_ENV === "development";
var searchParams = new URLSearchParams(window.location.search);

var fromExternal = false;
var customerData = {};
if(searchParams.has("isCustomer")) {
    fromExternal = true;
    customerData = {
        isCustomer: true,
        firstName: searchParams.get("firstName"),
        lastName: searchParams.get("lastName"),
        email: searchParams.get("email"),
        phoneNumber: searchParams.get("phone").replace(/\D/g,'').trim(),
        company: searchParams.get("company"),
        coreSystemIdentifier: searchParams.get("coreSystemIdentifier"),
    };
}

let app;
let apiKey="";
let loginUrl="";

const loadApp = async ({ url, clientId, realm, logoutRedirectUri }) => {
    if(!fromExternal) {
        app = createApp(App)
            .use(createPinia())
            .use(router)
            .use(AppInstaller);

        let isSessionValid = await Identity.initialize({ url, clientId, realm, logoutRedirectUri });
        if (!isSessionValid) {
            return;
        }
        UsersApi.getPermissions().then(perms => {
            // Set WalkMe variables
            window.arc_userGroupName = perms[0].name;
            window.arc_userGroupDescription = perms[0].description;
        });
    }
    else {
        app = createApp(RegisterApp, { customer: customerData, apiKey: apiKey, loginUrl: loginUrl })
            .use(createPinia())
            .use(router);
    }

    await loadScript(FragmentScriptHelper.arcFragmentsScriptUrl, "module");

    router.app = app;
    await router.isReady();    
    app.mount("#app");
};


EnvironmentsApi.
    getConfig()
    .then((response) => {
        let authRealm = response.keyCloakRealm;
        let authUrl = response.keyCloakUrl;
        let authClientId = response.keyCloakClientId;
        let authRedirectUrl = response.keyCloakLogoutRedirect;
        // Note: this is the api key that gets passed to the header of the request to endpoint (register). 
        // that endpoint allows anonymous since during this time the user is not authenticated.
        // imran and akshay talked about putting this in a collection as a permanent solution
        apiKey = response.apiKey;
        loginUrl = authRedirectUrl

        loadApp({
            url: authUrl,
            clientId: authClientId,
            realm: authRealm,
            logoutRedirectUri: authRedirectUrl
        });
    })
    .catch(error => {
        console.error(error);
    });

if (!isDevelopmentEnv && location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
}