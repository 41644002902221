<template>
    <header class="app-header navbar navbar-light bg-white">
        <ul v-if="hasHeaderTitle" class="nav">
            <li class="nav-text view-title">{{headerTitle}}</li>
        </ul>
        <div id="search-div" v-else class="app-search-input">
            <div class="app-search-icon">
                <i class="fal fa-search"></i>
            </div>
            <input
                class="form-control"
                type="text"
                placeholder="Search for assets..."
                v-model="globalSearchText"
                @keyup.enter="onSearch"
            >
        </div>
        <ul class="nav flex-row ms-auto">
            <!-- NOT PART OF MVP -->
            <!-- <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent="onHelpClick">
                    <i class="fas fa-question-circle"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent="onNotificationsClick">
                    <i class="fas fa-bell"></i>
                </a>
            </li> -->
            <li v-if="canViewSettings" class="nav-item">
                <RouterLink :to="{ name: 'settings:attributes' }" class="nav-link">
                    <i class="fas fa-gear"></i>
                </RouterLink>
            </li>
            <li class="nav-item">
                <div id="rq-auth-icon" class="rq-dropdown">
                    <a class="rq-link rq-dropdown-toggle" id="rq-auth-icon" role="button" @click="showUserMenu = !showUserMenu" aria-expanded="false">
                        <svg id="rq-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z"/>
                        </svg>
                    </a>
                    <ul id="rq-auth-dropdown" class="rq-dropdown-menu rq-dropdown-menu-end" aria-labelledby="rq-auth-icon" v-show="showUserMenu">
                        <li><span class="rq-dropdown-item-disabled">{{userInfo}}</span></li>                        
                        <li><a class="rq-dropdown-item" id="system-info" @click="onSystemInfoClick">System</a></li>
                        <li><a class="rq-dropdown-item" id="rq-auth-logout" @click="onSignOut">Logout</a></li>
                    </ul>
                </div>
            </li>
            <!-- <li>
                <button @click="onSignOut">Sign out</button>
            </li> -->
        </ul>
    </header>
        <arc-system-info-modal
            v-if="showSystemInfoModal"
            @close="onClose"
        />
</template>

<style>
</style>

<script>
    import { AppStateStore } from "@/stores/appState";
    import { AuthStateStore } from "@/stores/authState";
    import { mapState, mapWritableState, mapActions } from "pinia";
    import { routeNames } from "@/shared/models/route-names";
    import { roles } from "@/shared/helpers/roles";
    import { Identity } from "@/shared/services/identity.service";
    import { UsersApi } from "@/api";

    export default {
        name: "AppHeader",
        data() {
            return {
                canViewSettings: false,
                userDropdownOpen: false,
                showUserMenu: false,
                showSystemInfoModal: false
            };
        },
        computed: {            
            ...mapWritableState(AppStateStore, ["globalSearchText", "invokeGlobalSearch"]),
            ...mapState(AuthStateStore, [ "isAuthenticated" ]),
            headerTitle() { return _.get(this, "$route.meta.headerTitle", null); },
            hasHeaderTitle() { return !_.isEmpty(this.headerTitle); },
            userInfo() { return `${Identity.keycloak.tokenParsed.name} (${Identity.keycloak.tokenParsed.email})`; }
        },
        mounted() {
            this.getPermissions();
        },
        updated() {
            this.getPermissions();
        },
        methods: {
            ...mapActions(AuthStateStore, ['logout']),
            getPermissions() {
                UsersApi.getPermissions().then(result => {
                    // For MVP, users will only be a part of one group. We're undecided about how to handle
                    // conflicting permissions in multiple groups and plan on figuring that out in the future.
                    this.canViewSettings = result[0].permissions.some(p => p.role === roles.OWNER || p.role === roles.VIEW_AND_EDIT);
                });
            },
            onClose() {
                this.showSystemInfoModal = false;
            },
            onHelpClick() {},
            onNotificationsClick() {},
            onSearch() {
                let self = this;
                if(self.$route.name !== routeNames.HOME_SEARCH) {
                    self.$router.push({name: routeNames.HOME_SEARCH})
                        .then(() => self.invokeGlobalSearch = crypto.randomUUID());
                }
                else {
                    self.invokeGlobalSearch = crypto.randomUUID();
                }
            },
            onSystemInfoClick() {
                let self = this;
                self.showSystemInfoModal = true;
                self.showUserMenu = false;
            },
            onSignOut() {
                Identity.logOut();
            }
        }
    };
</script>