<template>
    <AppSidebar @mousemove="onMouseMove" />
    <div class="app-main" @mousemove="onMouseMove">
        <AppHeader />
        <main class="app-body" :class="{ 'borderless': borderlessContent, 'with-breadcrumb': showBreadcrumb }">
            <RqBreadcrumbs v-if="showBreadcrumb" />
            <RouterView />
        </main>
        <arc-idle-timeout-modal
            v-if="showIdleDialog"
            title="Time out"
            :secondsRemaining="idleSecondsRemaining"
            :timeout="idleTimeout"
            @continue="onContinue"
            @logout="onLogout"
        />
    </div>
</template>

<script>
    import { mapState } from "pinia";
    import { AppStateStore } from "@/stores/appState";
    import { AuthStateStore } from "@/stores/authState";
    import { Identity } from "@/shared/services/identity.service";
    import { loadGoogleAnalytics } from "@/shared/thirdPartyScripts/thirdPartyScriptLoaders";
    import { AppHeader, AppSidebar } from "./layout";
    import { RqBreadcrumbs } from "./components/bs/";
    
    const pageTitleRoot = "ARC";

    export default {
        name: "App",
        components: {
            AppHeader,
            AppSidebar,
            RqBreadcrumbs
        },
        data() {
            return {
                showIdleDialog: false,
                idleTimer: 0,
                idleSecondsRemaining: 60
            };
        },
        computed: {
            ...mapState(AppStateStore, ["isBusy"]),
            ...mapState(AuthStateStore, ["isAuthenticated"]),
            ...mapState(AuthStateStore, ["tokenExpirationTimeout"]),
            // 60000 is 1 minute in milliseconds
            idleTimeout() { return (this.tokenExpirationTimeout - 60000) / 1000; },
            showBreadcrumb() { return _.get(this, "$route.meta.showBreadcrumb", false); },
            borderlessContent() {
                return _.startsWith(this.$route.name, "settings");
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    let pageTitle = pageTitleRoot;
                    let pageName = to?.matched[1]?.meta?.label;
                    let settingsText = to?.matched[1]?.meta?.headerTitle;
                    let subPageName = to?.matched[2]?.meta?.label;
                    if(pageName) {
                        pageTitle += ` | ${pageName}`;
                    }
                    if(settingsText) { // This shouldn't show up if pageName is truthy
                        pageTitle += ` | ${settingsText}`;
                    }
                    if(subPageName) {
                        pageTitle += ` | ${subPageName}`;
                    }
                    document.title = pageTitle;
                }
            }
        },
        mounted() {           
            if (this.isAuthenticated) {
                // We have to use Web Workers for the timeout. Browsers will de-prioritize resources for inactive tabs,
                // meaning that IdleJS, setInterval, setTimeout, etc. can't accurately track the time on their own.
                // Web Workers create a separate thread so that the seconds can be counted correctly without interruption.
                const blob = new Blob([`setInterval(() => postMessage(0), 1000);`]);
                const workerScript = URL.createObjectURL(blob);
                this.worker = new Worker(workerScript);
                this.worker.onmessage = () => {
                    this.idleTimer += 1;
                    if (this.idleTimer === this.idleTimeout) this.showIdleDialog = true;
                    if (this.showIdleDialog) this.idleSecondsRemaining--;
                    if (this.idleSecondsRemaining === 0) this.onLogout();
                };
            }

            loadGoogleAnalytics();
        },
        methods: {
            onMouseMove() {
                this.idleTimer = 0;
            },
            onContinue() {
                this.showIdleDialog = false;
                this.idleTimer = 0;
                this.idleSecondsRemaining = 60;
                Identity.refreshToken();
            },
            onLogout() {
                this.showIdleDialog = false;
                Identity.logOut(location.href);
            }
        }
    }
</script>